<template>
	<div id="app">
		<template v-if="initializeStatus.isInit">
			<div style="display:flex;align-items:center;justify-content:center;height:100vh">
				{{initializeStatus.message}}
			</div>
		</template>
		<template v-else>
			<Layout v-if="isLogged && vision=='admin'">
				<router-view />
				<ModalUser ref='modalUser' />
			</Layout>
			<AppSeller v-else-if="isLogged && vision=='seller'">
				<router-view />
				<ModalUser ref='modalUser' />
			</AppSeller>
			<AuthLogin v-else></AuthLogin>

		</template>
		<Messages />
		<version-validate></version-validate>
	</div>
</template>

<style lang="scss">
</style>

<script>
// @ is an alias to /src
import Layout from '@/views/layout/Layout.vue'
import Messages from '@/components/Messages.vue'
import AuthLogin from './views/auth/AuthLogin.vue'
import AppSeller from './views/app_seller/AppSeller.vue'
import Api from './modules/api/api'
import ModalUser from './components/ModalUser.vue'
import VersionValidate from './components/VersionValidate.vue'

export default {
	name: 'StockWebApp',
	metaInfo: {
		title: 'Stock Admin',
		titleTemplate: ''
	},
	components: {
		Layout,
		Messages,
		AuthLogin,
		AppSeller,
		ModalUser,
		VersionValidate
	},
	data() {
		return {
			initializeStatus: {
				isInit: true,
				message: ""
			},
			vision: localStorage.getItem("owner_id") != null ? 'admin' : 'seller'
		}
	},
	mounted() {
		this.$app.instance = this
		this.init()
	},
	computed: {
		isLogged() {
			return localStorage.getItem("auth") != null && localStorage.getItem("auth_logged") != null
		},
		isSeller() {
			return localStorage.getItem("seller_id") != null
		}
	},
	methods: {
		isAdmin() {
			return this.vision == "admin";
		},
		async getOwner() {
			const current = await Api.owner.current()
			this.$app.owner = current;
		},
		async getOwnerConfigs() {
			this.initializeStatus.message = "Buscando informações do cliente...";
			try {
				await this.getOwner()
				const ownerId = localStorage.getItem("owner_id")
				const sellerId = localStorage.getItem("seller_id")
				const ownerConfigs = sessionStorage.getItem("owner_configs")
				if (ownerId != null || sellerId != null) {
					if (ownerConfigs == null) {
						const configs = await Api.owner.currentConfig()
						sessionStorage.setItem("owner_configs", JSON.stringify(configs))
						this.$app.owner_configs = configs;
					}
					else {
						this.$app.owner_configs = JSON.parse(ownerConfigs)
					}
				}
			} catch (e) {
				this.initializeStatus.message = "Problema ao carregar as informações";
			}
		},
		async delay() {
			return new Promise((accept) => {
				setTimeout(() => {
					accept(null)
				}, 100)
			})
		},
		async init() {
			this.initializeStatus.isInit = true;
			await this.getOwnerConfigs()
			this.initializeStatus.isInit = false;
			while (this.$app.messages == null) {
				await this.delay()
			}
		},
		logout() {
			localStorage.removeItem("auth");
			localStorage.removeItem("auth_logged");
			localStorage.removeItem("user_data");
			localStorage.removeItem("owner_id");
			localStorage.removeItem("seller_id");
			sessionStorage.removeItem("owner_configs");
			location.reload();
		},
	}
}
</script>

