<template>
	<Modal :title="'Usuário'" v-model="popupUser">
		<vs-input label="Name" v-model="userData.name" style="width:100%;margin-bottom:15px" />
		<vs-input label="Telefone" v-model="userData.phone" style="width:100%;margin-bottom:15px" />
		<template #footer>
			<div>
				<div>
					<vs-button @click="save()" style="margin-right:10px">Salvar</vs-button>
					<vs-button @click="changeClient()" type="flat">Trocar cliente</vs-button>
					<vs-button @click="logout()" type="flat">Sair da conta</vs-button>
				</div>
				<p class="caption" style="margin-top:10px">Version app: v{{metaData.version}} | Version api: v{{metaDataApi.version}}</p>
			</div>
		</template>
	</Modal>
</template>

<script>
import Api from '@/modules/api/api';
import meta_data from '@/meta_data';
import Modal from './Modal.vue';
export default {
	name: "StockWebModalUser",
	props: {
		onGetUser: Function
	},
	data() {
		return {
			popupUser: false,
			userData: { name: "", phone: "" },
			metaData: meta_data,
			metaDataApi: {
				version: "0"
			}
		};
	},
	mounted() {
		this.init()

	},
	methods: {
		init() {
			this.getUserData();
			this.getApiMetaData();
		},
		show() {
			this.popupUser = true;
		},
		async getApiMetaData() {
			try {
				this.metaDataApi = await Api.others.meta();
			}
			catch (e) {
				this.$app.messages.error(e)
			}
		},
		async getUserData() {
			if (localStorage.getItem("auth") != null) {
				try {
					this.userData = await Api.auth.currentUser()
					this.$store.commit("user", this.userData)
				} catch (e) {
					this.$app.messages.error(e)
				}
			}

		},
		async save() {
			this.$app.promise(async () => {
				await Api.auth.currentUserSave(this.userData);
				localStorage.setItem("user_data", JSON.stringify(this.userData));
				this.$app.messages.success("Salvo");
				this.popupUser = false;
			});
		},
		logout() {
			this.$app.instance.logout()
		},
		changeClient() {
			localStorage.removeItem("auth_logged");
			localStorage.removeItem("owner_id");
			localStorage.removeItem("seller_id");
			location.href = "/#/";
			location.reload();
		}
	},
	components: { Modal }
};
</script>

<style lang="scss" scoped>
</style>