<template>
	<div>
		<div class="app-input">

			<label v-if="label != null">
				<slot name="label">{{label}}</slot> <span class="validation-text" v-if="validation=='danger'">
					({{ validationsProps['danger-text']  }})
				</span>
			</label>

			<div :class="`input-container validation-${validation} size-${size}`">
				<input ref="input" v-model="internalValue" @keydown="keydown" @keyup="keyup" @keypress="keypress" style="width:100%" @blur="blur($event)" @focus="onFocus($event)" :placeholder="placeholder" :type="type" :disabled="disabled" :maxlength="maskLength>-1?maskLength:maxlength">
				<div>
					<slot name="actions"></slot>
				</div>
				<div v-if="validation != ``" :class="`validation-icon ${validation}`"><app-icon :i="validationIcon" style="font-size:20px"></app-icon></div>
			</div>

		</div>
	</div>
</template>

<script>
import { ValidationSchemas } from '@/utils/validations';
import { currencyMask, numberMask, dateMask, defaultMask } from '@/utils/masks';
export default {
	name: 'StockWebInput',
	props: {
		label: String,
		value: [String, Number],
		validations: Function,
		mask: String,
		size: {
			type: String,
			default: 'normal',
		},
		name: String,
		form: Object,
		placeholder: String,
		maxlength: String,
		type: String,
		disabled: Boolean,
		onEnter: Function,
	},
	data() {
		return {
			internalValue: "",
			validationsProps: {
				danger: false,
				success: false,
				"danger-text": "",
				"val-icon-danger": "close",
				"val-icon-success": "check",
			},
			maskLength: -1
		};
	},
	computed: {
		validation() {
			if (this.validationsProps.success) return "success"
			else if (this.validationsProps.danger) return "danger"
			return "";
		},
		validationIcon() {
			if (this.validationsProps.success) return "check"
			else if (this.validationsProps.danger) return "close"
			return "";
		}
	},
	watch: {
		value(v) {
			this.internalValue = v;
		},
		internalValue(v) {
			if (this.mask == "currency") this.internalValue = currencyMask(v)
			else if (this.mask == "number") this.internalValue = numberMask(v)
			else if (this.mask == "date") this.internalValue = dateMask(v)
			else if (this.mask != "" && this.mask != null) {
				this.internalValue = defaultMask(v, this.mask)
				this.maskLength = this.mask.length
			}
			this.validate()
			this.$emit("input", this.internalValue)
			if (this.value != this.internalValue) this.$emit("change", this.internalValue)
		}
	},
	mounted() {
		this.internalValue = this.value;
		if (this.form != null) this.form.register(this.name ?? this.label, {
			validate: () => this.validate(),
			clear: () => this.cleanMessages()
		})
		this.cleanMessages()
	},
	methods: {
		focus() {
			this.$refs.input.focus()
		},
		select() {
			this.$refs.input.select()
		},
		cleanMessages() {
			this.validationsProps.danger = false;
			this.validationsProps.success = false;
		},
		blur($event) {
			this.validate()
			this.$emit("blur", $event)
		},
		onFocus($event) {
			this.$emit("focus", $event)
		},
		keydown($event) {
			if ($event.key == "Enter") this.$emit("enter")
			this.$emit("keydown", $event)
		},
		keyup($event) {
			if ($event.key == "Enter") this.$emit("enter")
			this.$emit("keyup", $event)
		},
		keypress($event) {
			this.$emit("keypress", $event)
		},
		validate() {
			const vs = this.validations != null ? this.validations(ValidationSchemas) : []
			this.cleanMessages()
			for (const v of vs) {
				const error = v(this.internalValue)
				if (error != null) {
					this.validationsProps.danger = true;
					this.validationsProps["danger-text"] = error
					return false
				}
			}
			if (vs.length > 0) this.validationsProps.success = this.internalValue != "" ? true : false;

			return true
		},
		element() {
			return this.$refs.input
		},
	},
};
</script>

<style lang="scss" scoped>
.app-input {
	label {
		font-size: 14px;
		margin-left: 2px;
	}
	input {
		border: 0px;
	}
	.input-container {
		position: relative;
		display: flex;
		align-items: center;
		border-radius: 5px;
		border: 1px solid rgba(0, 0, 0, 0.2);
		transition: 0.2s;
		overflow: hidden;
		.validation-icon {
			width: 30px;
			height: 100%;
			transition: 0.2s;
			display: flex;
			align-items: center;
			justify-content: center;
			&.success {
				color: rgba(var(--vs-success), 1);
				background-color: rgba(var(--vs-success), 0.3);
			}
			&.danger {
				color: rgba(var(--vs-danger), 1);
				background-color: rgba(var(--vs-danger), 0.3);
			}
		}
	}

	.input-container:focus-within {
		box-shadow: 0 3px 10px 0 #e8e8e8;
		border: 1px solid rgba(var(--vs-primary), 1);
	}
	.size-small {
		height: 20px;
		input {
			padding: 10px;
			font-size: 14px;
		}
	}
	.size-normal {
		height: 37px;
		input {
			padding: 10px;
			font-size: 14px;
		}
	}
	.size-large {
		height: 50px;
		input {
			padding: 10px;
			font-size: 16px;
		}
	}
	.validation-success {
		border: 1px solid rgba(var(--vs-success), 1) !important;
	}
	.validation-danger {
		border: 1px solid rgba(var(--vs-danger), 1) !important;
	}
	.validation-text {
		margin-top: 4px;
		font-size: 12px;
		color: rgba(var(--vs-danger), 1) !important;
	}
}
</style>