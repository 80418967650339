import moment from 'moment';

const Format = {
    shortId(id) {
        if (id == null) return ""
        if (id.length == 36) {
            // return id.substr(0, 5) + "..." + id.substr(31, 35)
            return id.substr(0, 8).toUpperCase()
        }
        return id
    },
    dshow(date, time = true) {
        if (date == null) return ""
        return moment(date).format(time ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY ")
    },
    money(value, prefix = "R$ ") {
        value = parseFloat(value)
        if (value?.toFixed != null)
            return prefix + value.toFixed(2)
        return value
    },
    address(addr) {
        if (addr == null) return ""
        return `${addr.street ?? ""} ${addr.number}, ${addr.district}, ${addr.city},${addr.state},${addr.cep}`
    }
}
export default Format