import makeClient from "@/models/client";
import makePayment from "@/models/payment";
import makeRequest from "@/models/request";
import ApiProvider from "./api_provider";

const ApiSeller = {
    clients: {
        async save(data) {
            return (await ApiProvider.post("api_seller/client/save", data)).data
        },
        async list(options) {
            const result = (await ApiProvider.get("api_seller/client/list", options)).data
            result.results = result.results.map((i) => makeClient(i))
            return result
        },
        async getById(id) {
            return makeClient((await ApiProvider.get("api_seller/client/get/" + id)).data)
        },
    },
    requests: {
        async list(options) {
            const result = (await ApiProvider.get("api_seller/request/list", options)).data
            result.results = result.results.map((i) => makeRequest(i))
            return result
        },
        async create(data) {
            return makeRequest((await ApiProvider.post("api_seller/request/create", data)).data)
        },
        async getById(id) {
            return makeRequest((await ApiProvider.get("api_seller/request/get/" + id)).data)
        },
    },
    payments: {
        async list(options) {
            const result = (await ApiProvider.get("api_seller/payment/list", options)).data
            result.results = result.results.map((i) => makePayment(i))
            return result
        },
    },
    products: {
        async search(options) {
            const result = (await ApiProvider.get("api_seller/product/search", options)).data
            return result
        },
        async getById(id) {
            return (await ApiProvider.get("api_seller/product/get/" + id)).data
        },
    },
    reports: {
        async comissao(params) {
            const result = (await ApiProvider.get("api_seller/reports/comissao", params)).data
            return { payments: result.payments.map((i) => makePayment(i)), seller: result.seller }
        },
        async requests(params) {
            const result = (await ApiProvider.get("api_seller/reports/requests", params)).data
            return { requests: result.requests.map((i) => makeRequest(i)) }
        },
    },
}


export default ApiSeller