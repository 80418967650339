<template>
	<div class="container">
		<div class="header">
			<div class="stock">
				<img src="/img/icons/stock-icon.png" style="width:30px" />
				Stock Admin
			</div>
			<div class="space"></div>
			<div><a class="popup-item" v-if="auth==null" :href="`${$env.VUE_APP_HOST_STORE}#/register`" target="_blank">Criar minha conta</a>
				<a class="popup-item" v-else @click="logout">Entrar com outra conta</a>
			</div>
		</div>
		<layout-auth style="width:100% ">
			<div class="login">
				<template v-if="auth==null">
					<h1>Stock Admin</h1>
					<div style="margin-bottom:10px">
						Informe as credenciais da sua conta <b>Stock</b> para acessar
						<p>Caso não possua uma conta, <a href>clique aqui para criar a sua</a></p>
					</div>
					<Input label="E-mail" size="large" v-model="form.email" :form="validationForm" :validations="validations.email" @enter="login"></Input>
					<Input label="Senha" size="large" v-model="form.password" :form="validationForm" :validations="validations.required" type="password" @enter="login"></Input>
					<div class="divider"></div>
					<div class="footer">
						<div><input type="checkbox" v-model="remember">Lembrar e-mail</div>
						<vs-button type="gradient" @click="login()">Login</vs-button>
					</div>

				</template>
				<template v-else>
					<div>
						<div class="dflex flex-ac" style="justify-content:space-between">
							<h1>Stock Admin - Acesso</h1>
						</div>

						<div style="margin-bottom:10px">
							Escolha seu tipo de acesso
						</div>
						<div style="height:10px"></div>
						<div class="dflex-c gap">
							<div class="item" v-for="item in admins" :key="item.owner.name" @click="accessOwner(item.owner.id)">
								<vs-icon icon="navigate_next"></vs-icon>
								<div class="dflex flex-ac gap">
									<vs-icon icon="storefront"></vs-icon>
									Loja - {{ item.owner.name }}
								</div>
							</div>
							<div class="item" v-for="item in sellers" :key="item.owner.name" @click="accessSeller(item.seller_id)">
								<vs-icon icon="navigate_next"></vs-icon>
								<div class="dflex flex-ac gap">
									<vs-icon icon="face"></vs-icon>
									Vender - {{ item.owner.name }}
								</div>
							</div>
						</div>
						<div class="divider" v-if="admins.length>0 || sellers.length>0"></div>
						<div class="dflex-c gap">
							<div class="item" @click="popupOwner=true">
								<vs-icon icon="storefront"></vs-icon>
								Cria minha loja virtual
							</div>
							<div class="item" @click="popupSeller=true">
								<vs-icon icon="face"></vs-icon>
								Sou vendedor, vincular acesso á uma loja existente
							</div>
						</div>
						<div style="height:10px"></div>
					</div>
					<div class="footer"></div>
				</template>
			</div>
		</layout-auth>
		<Modal title="Adicionar novo acesso" v-model="popupSeller">
			Informe o código enviado pelo administrador da loja.
			<Input v-model="sync" size="large" />
			<template #footer> <vs-button @click="syncSeller()">Vincular</vs-button></template>

		</Modal>
		<Modal title="Criar nova loja" v-model="popupOwner">
			<Input v-model="newOwner" label="Nome da loja" size="large" />
			<template #footer>
				<vs-button @click="createOwner()">Criar</vs-button>
			</template>
		</Modal>
	</div>
</template>

<script>
import Api from '@/modules/api/api';
import LayoutAuth from '../layouts/LayoutAuth.vue';
import { ValidationForm } from '@/utils/validations';
import Input from '@/components/form/Input.vue';
import Modal from '@/components/Modal.vue';
export default {
	components: { Input, LayoutAuth, Modal },
	name: 'StockWebAuthLogin',

	data() {
		return {
			popupSeller: false,
			popupOwner: false,
			form: { email: "", password: "" },
			auth: localStorage.getItem("auth"),
			userData: {},
			admins: [],
			sellers: [],
			admin: "",
			seller: "",
			sync: "",
			newOwner: "",
			remember: true,
			validationForm: ValidationForm(),
			validations: {
				required(schemas) {
					return [schemas.required]
				},
				email(schemas) {
					return [schemas.required, (v) => {
						if (!v.match(/^\S+@\S+\.\S+$/)) return "E-mail inválido"
					}]
				},
			}
		};
	},

	mounted() {
		if (this.auth != null) {
			this.getPermissions()
			// this.getUserData()
		}
		const lastLogged = localStorage.getItem("lastLogged")
		if (lastLogged != null) {
			this.form.email = lastLogged
		}
	},

	methods: {
		getUserData() {
			this.$app.promise(async () => {
				const data = await Api.auth.currentUser()
				this.userData = data;
				localStorage.setItem("user_data", JSON.stringify(data))
			})
		},
		getPermissions() {
			this.$app.promise(async () => {
				const permissions = await Api.auth.permissions()
				for (const p of permissions) {
					if (p.type == "ADMIN") this.admins.push(p)
					else if (p.type == "SELLER") {
						p.seller_id = p.permissions.split("seller_id:")[1]
						this.sellers.push(p)
					}
				}
			})

		},
		login() {
			if (this.validationForm.validate()) {
				this.$app.promise(async () => {
					const auth = await Api.auth.login(this.form)
					if (this.remember) localStorage.setItem("lastLogged", this.form.email)
					else localStorage.removeItem("lastLogged")
					localStorage.setItem("auth", auth.token)
					this.auth = auth.token
					this.getPermissions()
					// this.getUserData()
					location.reload()
					// console.log(permissions)
				})
			}

		},
		logout() {
			localStorage.removeItem("auth");
			localStorage.removeItem("auth_logged");
			localStorage.removeItem("user_data");
			localStorage.removeItem("owner_id");
			localStorage.removeItem("seller_id");
			sessionStorage.removeItem("owner_configs")
			location.reload();
		},
		accessOwner(id) {
			localStorage.setItem("auth_logged", "true")
			localStorage.setItem("owner_id", id)
			location.reload()
		},
		accessSeller(id) {
			localStorage.setItem("auth_logged", "true")
			localStorage.setItem("seller_id", id)
			location.reload()
		},
		syncSeller() {
			this.$app.promise(async () => {
				await Api.sellers.sync(this.sync)
				this.popupSeller = false;
				location.reload()
			})
		},
		createOwner() {
			this.$app.promise(async () => {
				await Api.owner.create({ name: this.newOwner })
				this.popupOwner = false;
				location.reload()
			})
		},
		finish() {
			if (this.sync != "") {
				this.$app.promise(async () => {
					await Api.sellers.sync(this.sync)
					this.getPermissions()
					this.popupSeller = false
				})
				return
			}
			if (this.admin != "" || this.seller != "") {
				localStorage.setItem("auth_logged", "true")
				console.log(this.admin)
				console.log(this.seller)
				if (this.admin != "") localStorage.setItem("owner_id", this.admin)
				if (this.seller != "") localStorage.setItem("seller_id", this.seller)
				location.href = "/#/home"
				location.reload()
			}

		}
	},
};
</script>

<style lang="scss" scoped>
.container {
	margin: 10px 10vw;
	width: 80vw;
	display: flex;
	flex-direction: column;
	height: 100vh;
	align-items: center;

	p {
		margin-top: 10px;
	}
}
.login {
}
.stock {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	font-weight: bold;
	width: 100%;
	img {
		margin-right: 10px;
	}
}
.footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	input {
		margin-right: 5px;
	}
}
.header {
	display: flex;
	width: 100%;
	align-items: center;
	a {
		font-weight: bold;
		color: #2c3e50;
		transition: 0.2s;
		white-space: nowrap;
	}
}
.item {
	padding: 10px;
	border-radius: 10px;
	cursor: pointer;
	transition: 0.3s;
	background: #f8f8f8;
	display: flex;
	align-items: center;
	gap: 10px;
}
.item:hover {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
</style>